<template>
  <div class="space-y-10">
    <!-- pageTitle -->
    <div class="pageTitle">
      <h1>{{ $route.name }}</h1>
      <h3>{{ $route.meta.description }}</h3>
    </div>
    <!-- content -->
    <div>
      <RetailPackages :items="$store.state.retailPackages" />
    </div>
  </div>
</template>

<script>
import RetailPackages from "@/components/RetailPackages";
export default {
  components: { RetailPackages },
};
</script>